.App {
  text-align: center;
  font-family: Work Sans;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
  font-size: calc(10px + 2vmin);
  color: black;
}
h2{
  max-width: 800px;
}
.kontakt input{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 20px;
    display: block;

    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 300px;
    width: 100%;
}
h6.nadpis{
  font-size: 22px;
}
.kontakt{
    max-width: 350px;
    font-size: 18px;
}
.orientacna{
  font-size: 18px;
  padding-top: 0;
  margin-top: 0;
  color: rgba(0,0,0,.6);
}
.kontakt a{
    color: black;
}
.kontakt input[type='checkbox']{
    display: inline-block;
    width: initial;
    margin-bottom: 0;
}
form{
  text-align: left;
}
.App-link {
  color: black;
}
input[type='number']{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 20px;

}
i{
  padding-right: 10px;
}
.back{
  margin-top: 30px;
  font-size: 18px;
}
.back:hover{
  cursor: pointer;
  text-decoration: underline;
}
.odpoved{
  color: rgba(0,0,0,.6);
  font-size: 18px;
  width: 100%;
  margin-bottom: 40px;
}
.odpoved:after, .odpoved:before {
  content: "";
  width: 25%;
  height: 1px;
  background: rgba(0,0,0,.2);
  position: relative;
  display: inline-block;
  top: -5px;
}
.odpoved:after{
  margin-left: 10px;
}
.odpoved:before{
  margin-right: 10px;
}
input[type='submit']{
  display: block!important;
  border: 0!important;
  color: black!important;
  font-size: 32px!important;
  font-weight: 700!important;
  border: 2px solid black!important;
  margin: 0 auto!important;
  margin-top: 40px!important;
  text-transform: uppercase!important;
  background-color: initial!important;
  background: initial!important;
  background: black!important;
  color: white!important;
  background: black!important;
  color: white!important;
  font-size: 18px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  border-radius: 0!important;
  -webkit-appearance: initial!important;

}
.btn{
  background: black!important;
  color: white!important;
  font-size: 18px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
  padding-top: 10px!important;
  border: 2px solid black!important;
  padding-bottom: 10px!important;
  text-transform: uppercase!important;
  font-weight: 700!important;
}
.btn:hover,input[type='submit']:hover{
  cursor: pointer;
  color: black;
  background: white;
  border: 2px solid black;
}
.nadpis{
  font-weight: 700;
  text-transform: uppercase;
  color: #5e9dd7;
  font-size: 2em;
}
form{
  font-size: 20px;
}

input[type='radio']{
  margin-bottom: 15px;
  position: relative;
  top: -3px;
  margin-right: 10px;
}
input[type='text'], input[type='number'], input[type='email']{
  border: 1px solid rgb(238, 238, 238)!important;
}
input::placeholder{
  font-size: 14px;
}
.disabled{
  opacity: 0.4;
}
.disabled:hover{
  cursor: initial!important;
}
.blacked{
  color: black;
}

.btn, input[type=submit]{
  -webkit-appearance: initial!important;
  border-radius: 0!important;
}
@media screen and (max-width: 767px){
  .odpoved:after, .odpoved:before{
    width: 15%;
  }
}